<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div class="uk-margin">
      <div uk-grid class="uk-grid-small">
        <div class="uk-form-controls uk-width-small">
          <select class="uk-select" id="form-horizontal-select" v-model="filterType">
            <option :value=null>Select Filter</option>
            <option :value='"user_id"'>Mitra</option>
            <option :value='"company_id"'>Company</option>
            <option :value='"company_office_id"'>Property</option>
          </select>
        </div>
        <div class="uk-inline uk-width-1-4">
          <multiselect
            v-model="meta[filterType]"
            :placeholder="!filterType ? 'Select filter to search': 'Type to search'"
            label="name"
            name="mitra"
            track-by="name"
            :disabled="!filterType"
            :options="filterType === 'user_id' ? user_options : filterType === 'company_id' ? company_options : office_options"
            :searchable="true"
            :loading='isFetching'
            :internal-search="false"
            :options-limit="50"
            @search-change="val => onQueryChange(val)"
          >
          </multiselect>
        </div>
        <div class="uk-inline uk-width-small uk-margin-small-right">
          <datetime
            input-class="uk-input"
            type="date"
            name="date_filter"
            v-model="meta.date"
            placeholder="Filter Date"
          ></datetime>
        </div>
        <div class="uk-inline">
          <button style="width:100px; cursor:pointer; height:40px" class="uk-button-primary uk-margin-right" @click="showModal">Create</button>
          <button style="width:200px; cursor:pointer; height:40px" class="uk-button-primary uk-margin-right" @click="showModalAttd">Create from attendance</button>
          <button style="width:200px; cursor:pointer; height:40px" class="uk-button-primary" @click="showModalReport">Download Payment Report</button>
        </div>
      </div>
    </div>

    <div class="uk-card uk-card-default uk-margin">
      <div class="uk-overflow-auto">
        <table class="uk-table uk-table-small uk-table-divider uk-table-striped">
          <thead>
            <tr>
              <th class="uk-text-center uk-width-medium">Date</th>
              <th class="uk-text-center uk-width-medium">User (Mitra)</th>
              <th class="uk-text-center uk-width-medium">Company</th>
              <th class="uk-text-center uk-width-medium">Property</th>
              <th class="uk-text-center uk-width-medium">Checkin Time</th>
              <th class="uk-text-center uk-width-medium">Checkout Time</th>
              <th class="uk-text-center uk-width-medium">Normal Work (Hours)</th>
              <th class="uk-text-center uk-width-medium">Overtime Work (Hours)</th>
              <th class="uk-text-center uk-width-medium">Absent (Hours)</th>
              <th class="uk-text-center uk-width-medium">Leaves (Hours)</th>
              <th class="uk-text-center">Fee Unit</th>
              <th class="uk-text-center uk-width-medium">Daily Total Fee (Rp)</th>
              <th class="uk-text-center">Notes</th>
              <th class="uk-text-center">Updated By</th>
              <!-- <th class="uk-text-center">Status</th> -->
              <th class="uk-text-center uk-width-small">Action</th>
            </tr>
          </thead>
          <tbody v-if="daily_recaps.docs.length>0" class="uk-table-middle uk-text-center ">
            <tr v-for="(daily_recap, i) in daily_recaps.docs" :key="i">
              <td>
                {{daily_recap.date ? new Date(daily_recap.date).toLocaleDateString("id-ID", { year: 'numeric', month: 'long', day: 'numeric' }) : null}}
              </td>
              <td>{{daily_recap.user.fullname}}</td>
              <td>{{daily_recap.company.name}}</td>
              <td>{{daily_recap.company_office.name}}</td>
              <td>
                {{daily_recap.checkin_time ? new Date(daily_recap.checkin_time).toLocaleTimeString("id-ID") : null}}
              </td>
              <td>
                {{daily_recap.checkout_time ? new Date(daily_recap.checkout_time).toLocaleTimeString("id-ID") : null}}
              </td>
              <td>{{daily_recap.normal_work_hour || '-'}}</td>
              <td>{{daily_recap.overtime_work_hour_spv || '-'}}</td>
              <td>{{daily_recap.absent_hour || '-'}}</td>
              <td>{{daily_recap.leaves_hour || '-'}}</td>
              <td>{{daily_recap.fee_normal_unit}}</td>
              <td>{{daily_recap.fee_total}} {{daily_recap.fee_normal_unit==='Month' ? '(OVERTIME ONLY)' : null}}</td>
              <td>
                <p
                  v-if="daily_recap.notes"
                  class="uk-link"
                  @click="showNotes(daily_recap.notes)">
                    Read Notes
                  </p>
                </td>
              <td>{{daily_recap.updated_by}}</td>
              <!-- <td>
                <div
                  class="label uk-margin-auto"
                  :style="`width: 90px; background-color: ${daily_recap.status === 1 ? '#00cc00' : '#ff8000'}`">
                  {{daily_recap.status === 1 ? 'Reviewed' : 'Pending'}}
                </div>
              </td> -->
              <td>
                <button class="uk-button uk-button-small uk-button-default" type="button">Actions</button>
                <div uk-dropdown="mode: click">
                  <ul class="uk-nav uk-dropdown-nav uk-text-left">
                    <li><a @click="showDetail(daily_recap)">Log</a></li>
                    <li><a
                      v-clipboard:copy="daily_recap._id"
                      v-clipboard:success="onCopy">Copy ID</a>
                    </li>
                    <li class="uk-nav-divider"></li>
                    <li class="uk-nav-header">Data Action</li>
                    <li><a @click="editDailyRecap(daily_recap)">Edit</a></li>
                    <li><a
                      v-show="daily_recap.user_attendance"
                      @click="updateEmployerFlags(daily_recap.user_attendance._id, false)">Allow HR Edit</a>
                    </li>
                    <li><a
                      v-show="daily_recap.user_attendance"
                      @click="updateEmployerFlags(daily_recap.user_attendance._id, true)">Allow SPV Edit</a>
                    </li>
                    <li><a @click="showDeleteModal(daily_recap)">Delete</a></li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>
          <empty-table v-else :colspan="6" />
        </table>
      </div>
      <pagination
        :total-data="daily_recaps.totalDocs"
        :change-limit="changeLimit"
        :change-page="changePage"
      />
    </div>

    <div id="formReportModal" uk-modal esc-close="false" bg-close="false">
      <form class="uk-modal-dialog" style="width:40%" data-vv-scope="report-form">
        <div>
          <button class="uk-modal-close-default" type="button" uk-close @click="hideModalReport"></button>
          <div class="uk-modal-header">
            <h3>Input specification for Payment Report</h3>
          </div>
          <div class="uk-modal-body" uk-overflow-auto>
            <!-- TODO: IMPLEMENT FORM REPORT -->

            <div uk-grid>
              <div class="uk-width-1-2">
                <label class="uk-form-label">Company</label>
                <multiselect
                  v-model="reportForm.company_id"
                  placeholder="Type to search company"
                  label="name"
                  name="company"
                  track-by="name"
                  :options="company_options"
                  :searchable="true"
                  :loading='isFetching'
                  :internal-search="false"
                  :options-limit="50"
                  @search-change="setCompanyValues"
                >
                </multiselect>
              </div>

              <div class="uk-width-1-2">
                <label class="uk-form-label">Property's Name</label>
                <multiselect
                  v-model="reportForm.company_office_id"
                  placeholder="Type to search office"
                  label="name"
                  name="office"
                  track-by="name"
                  :options="office_options"
                  :searchable="true"
                  :loading='isFetching'
                  :internal-search="false"
                  :options-limit="50"
                  @search-change="setPropertyValues"
                >
                </multiselect>
              </div>
            </div>
            <div uk-grid>
              <div class="uk-width-1-2">
                <label class="uk-form-label">From <b class="uk-text-danger">*</b></label>
                <div class="uk-position-relative">
                  <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: calendar"></span>
                  <datetime
                    input-class="uk-input"
                    type="date"
                    :class="{'uk-form-danger': errors.has('report-form.start_date')}"
                    name="start_date"
                    v-model="reportForm.start_date"
                    placeholder="dd/mm/yyyy"
                    v-validate="'required'"
                  ></datetime>
                </div>
                <span class="uk-text-small uk-text-danger" v-show="errors.has('report-form.start_date')">
                  {{ errors.first('report-form.start_date') }}
                </span>
              </div>

              <div class="uk-width-1-2">
                <label class="uk-form-label">To <b class="uk-text-danger">*</b></label>
                <div class="uk-position-relative">
                  <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: calendar"></span>
                  <datetime
                    input-class="uk-input"
                    type="date"
                    :class="{'uk-form-danger': errors.has('report-form.end_date')}"
                    name="end_date"
                    v-model="reportForm.end_date"
                    placeholder="dd/mm/yyyy"
                    v-validate="'required'"
                  ></datetime>
                </div>
                <span class="uk-text-small uk-text-danger" v-show="errors.has('report-form.end_date')">
                  {{ errors.first('report-form.end_date') }}
                </span>
              </div>
            </div>
          </div>
          <div class="uk-modal-footer uk-text-right">
            <download-excel
              hidden
              ref="report_download"
              :data = "grouped_recap"
              :fields = "report_fields"
              name = "Payment Report.xls"
            >
            </download-excel>
            <button class="uk-button uk-button-primary" type="button" @click="downloadReport">Download</button>
          </div>
        </div>
      </form>
    </div>

    <div id="recapDetailModal" uk-modal esc-close="false" bg-close="false">
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default" type="button" uk-close @click="hideDetail"></button>
        <div class="uk-modal-header">
          <h3 class="uk-modal-title">Activity Logs</h3>
        </div>
        <div class="uk-modal-body" uk-overflow-auto>
          <div class="uk-margin">
            <div v-if="activity_logs.docs.length>0">
              <div v-for="(log, i) in activity_logs.docs" :key="i">
                <h4 class="uk-margin-top">Log #{{i + 1}}</h4>
                <p class="uk-margin-small">Date: <b>{{formatDate(log.data_before.date)}}</b></p>
                <p class="uk-margin-small">Check In Time: <b>{{formatDatetime(log.data_before.checkin_time, false) || '-'}}</b></p>
                <p class="uk-margin-small">Check Out Time: <b>{{formatDatetime(log.data_before.checkout_time, false) || '-'}}</b></p>
                <p class="uk-margin-small">Normal Workhour: <b>{{log.data_before.normal_work_hour}} hour(s)</b></p>
                <p class="uk-margin-small">Overtime Workhour: <b>{{log.data_before.overtime_work_hour}} hour(s)</b></p>
                <p class="uk-margin-small">Absent: <b>{{log.data_before.absent_hour}} hour(s)</b></p>
                <p class="uk-margin-small">Absent: <b>{{log.data_before.leaves_hour}} hour(s)</b></p>
                <p class="uk-margin-small">Total Fee: <b>Rp. {{log.data_before.fee_total || 0}}</b></p>
                <p class="uk-margin-small">Updated By: <b>{{log.data_before.updated_by}}</b></p>
                <p class="uk-margin-small">Updated On: <b>{{formatDatetime(log.data_before.updated_at, false)}}</b></p>
              </div>
            </div>
            <div>
              <h4 class="uk-margin-top">Latest</h4>
                 <p class="uk-margin-small">Date: <b>{{formatDate(dailyRecapForm.date)}}</b></p>
                <p class="uk-margin-small">Check In Time: <b>{{formatDatetime(dailyRecapForm.checkin_time, false) || '-'}}</b></p>
                <p class="uk-margin-small">Check Out Time: <b>{{formatDatetime(dailyRecapForm.checkout_time, false) || '-'}}</b></p>
                <p class="uk-margin-small">Normal Workhour: <b>{{dailyRecapForm.normal_work_hour}} hour(s)</b></p>
                <p class="uk-margin-small">Overtime Workhour: <b>{{dailyRecapForm.overtime_work_hour}} hour(s)</b></p>
                <p class="uk-margin-small">Absent: <b>{{dailyRecapForm.absent_hour}} hour(s)</b></p>
                <p class="uk-margin-small">Absent: <b>{{dailyRecapForm.leaves_hour}} hour(s)</b></p>
                <p class="uk-margin-small">Total Fee: <b>Rp. {{dailyRecapForm.fee_total || 0}}</b></p>
                <p class="uk-margin-small">Updated By: <b>{{dailyRecapForm.updated_by}}</b></p>
                <p class="uk-margin-small">Updated On: <b>{{formatDatetime(dailyRecapForm.updated_at, false)}}</b></p>
            </div>
          </div>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button class="uk-button uk-button-primary" type="button" @click="hideDetail">OK</button>
        </div>
      </div>
    </div>

    <div id="formRecapAttdModal" uk-modal esc-close="false" bg-close="false">
      <form class="uk-modal-dialog uk-margin-auto-vertical" data-vv-scope="attd-form">
        <div>
          <button class="uk-modal-close-default" type="button" uk-close @click="hideModalAttd"></button>
          <div class="uk-modal-header">
            <h3 class="uk-modal-title">Create Recap from Attendances</h3>
          </div>
          <div class="uk-modal-body">
            <div class="uk-margin">
              <label class="uk-form-label">Select Attendance Date <b class="uk-text-danger">*</b></label>
              <datetime
                input-class="uk-input"
                type="date"
                :class="{'uk-form-danger': errors.has('attd-form.attendance_date')}"
                name="attendance_date"
                v-model="attendance_date"
                placeholder="Date"
                v-validate="'required'"
              ></datetime>
              <span class="uk-text-small uk-text-danger" v-show="errors.has('attd-form.attendance_date')">{{ errors.first('attd-form.attendance_date') }}</span>
            </div>
          </div>
          <div class="uk-modal-footer uk-text-right">
            <button class="uk-button uk-button-primary" type="button" @click="createFromAttd">Create</button>
          </div>
        </div>
      </form>
    </div>

    <div id="recapNotesModal" uk-modal esc-close="false" bg-close="false">
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default" type="button" uk-close @click="hideNotes"></button>
        <div class="uk-modal-header">
          <h3 class="uk-modal-title">Recap Notes</h3>
        </div>
        <div class="uk-modal-body">
          <div class="uk-margin-small">
            <p style="white-space: pre-line">{{this.notes}}</p>
          </div>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button class="uk-button uk-button-primary" type="button" @click="hideNotes">OK</button>
        </div>
      </div>
    </div>

    <div id="formRecapModal" uk-modal esc-close="false" bg-close="false">
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default" type="button" uk-close @click="hideModal"></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title">Daily Recap Form</h2>
        </div>
        <div class="uk-modal-body" uk-overflow-auto>

          <div class="uk-margin">
            <label class="uk-form-label">Recap Date <b class="uk-text-danger">*</b></label>
            <datetime
              input-class="uk-input"
              type="date"
              :class="{'uk-form-danger': errors.has('date')}"
              name="date"
              v-model="dailyRecapForm.date"
              placeholder="Date"
              v-validate="'required'"
            ></datetime>
            <span class="uk-text-small uk-text-danger" v-show="errors.has('date')">{{ errors.first('date') }}</span>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">User <b class="uk-text-danger">*</b></label>
            <multiselect
              v-model="dailyRecapForm.user_id"
              placeholder="Type to search user (by name)"
              label="name"
              name="user"
              track-by="name"
              v-validate="'required'"
              :options="user_options"
              :searchable="true"
              :loading='isFetching'
              :internal-search="false"
              :options-limit="50"
              @search-change="setUserValues"
              @select="item => setJobPartnerValues(item.value)"
            >
            </multiselect>
            <span class="uk-text-small uk-text-danger" v-show="errors.has('user')">{{ errors.first('user') }}</span>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">Company <b class="uk-text-danger">*</b></label>
            <multiselect
              v-model="dailyRecapForm.company_id"
              placeholder="Type to search company"
              label="name"
              name="company"
              track-by="name"
              v-validate="'required'"
              :options="company_options"
              :searchable="true"
              :loading='isFetching'
              :internal-search="false"
              :options-limit="50"
              @search-change="setCompanyValues"
            >
            </multiselect>
            <span class="uk-text-small uk-text-danger" v-show="errors.has('company')">{{ errors.first('company') }}</span>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">Office (Property) <b class="uk-text-danger">*</b></label>
            <multiselect
              v-model="dailyRecapForm.company_office_id"
              placeholder="Type to search office"
              label="name"
              name="office"
              track-by="name"
              v-validate="'required'"
              :options="office_options"
              :searchable="true"
              :loading='isFetching'
              :internal-search="false"
              :options-limit="50"
              @search-change="setPropertyValues"
            >
            </multiselect>
            <span class="uk-text-small uk-text-danger" v-show="errors.has('office')">{{ errors.first('office') }}</span>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">Job Partner <b class="uk-text-danger">*</b></label>
            <multiselect
              v-model="dailyRecapForm.job_partner_id"
              placeholder="Select job partner"
              label="name"
              name="job_partner"
              track-by="name"
              v-validate="'required'"
              :options="job_partner_options"
              :searchable="true"
              :options-limit="50"
              @select="item => fillFees(item.role_property)"
            >
            </multiselect>
            <span class="uk-text-small uk-text-danger" v-show="errors.has('job_partner')">{{ errors.first('job_partner') }}</span>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">Normal Fee (Rp)<b class="uk-text-danger">*</b></label>
            <input
              class="uk-input"
              :class="{'uk-form-success': feeFilled, 'uk-form-danger': errors.has('fee_normal')}"
              name="fee_normal"
              type="number"
              v-model="dailyRecapForm.fee_normal"
              v-validate="'required'"
              placeholder="Normal Fee">
            <span class="uk-text-small uk-text-danger" v-show="errors.has('fee_normal')">{{ errors.first('fee_normal') }}</span>
          </div>

          <div class="uk-margin-small uk-width-1-2">
            <label class="uk-form-label">Normal Fee Unit <b class="uk-text-danger">*</b></label>
            <multiselect
              :class="{'uk-form-success': feeFilled, 'uk-form-danger': errors.has('fee_normal_unit')}"
              placeholder="Fee Unit"
              name="fee_normal_unit"
              v-model="dailyRecapForm.fee_normal_unit"
              v-validate="'required'"
              :options="unit_options">
            </multiselect>
            <span class="uk-text-small uk-text-danger" v-show="errors.has('fee_normal_unit')">{{ errors.first('fee_normal_unit') }}</span>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">Overtime Fee Hourly (Rp)<b class="uk-text-danger">*</b></label>
            <input
              class="uk-input"
              :class="{'uk-form-success': feeFilled, 'uk-form-danger': errors.has('fee_overtime_hourly')}"
              name="fee_overtime_hourly"
              type="number"
              v-model="dailyRecapForm.fee_overtime_hourly"
              v-validate="'required'"
              placeholder="Overtime Fee">
            <span class="uk-text-small uk-text-danger" v-show="errors.has('fee_overtime_hourly')">{{ errors.first('fee_overtime_hourly') }}</span>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">Break Time (Hours)</label>
            <input
              class="uk-input"
              :class="{'uk-form-success': feeFilled, 'uk-form-danger': errors.has('break_time')}"
              name="break_time"
              type="number"
              v-model="dailyRecapForm.break_time"
              placeholder="Break Time">
          </div>

          <div class="uk-margin">
            <label :class="{'uk-text-success': feeFilled}" class="uk-form-label">
              <input
                class="uk-checkbox uk-margin-small-right"
                :class="{'uk-form-success': feeFilled}"
                name="totaltime_includes_break"
                type="checkbox"
                v-model="dailyRecapForm.totaltime_includes_break"
              >
                Workhour includes break
            </label>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">Checkin Time</label>
            <datetime
              input-class="uk-input"
              type="datetime"
              :class="{'uk-form-danger': errors.has('checkin_time')}"
              name="checkin_time"
              v-model="dailyRecapForm.checkin_time"
              placeholder="Checkin Time"
            ></datetime>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">Checkout Time</label>
            <datetime
              input-class="uk-input"
              type="datetime"
              :class="{'uk-form-danger': errors.has('checkout_time')}"
              name="checkout_time"
              v-model="dailyRecapForm.checkout_time"
              placeholder="Checkout Time"
            ></datetime>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">Normal Workhour (Hours without break)</label>
            <input
              class="uk-input"
              :class="{'uk-form-danger': errors.has('normal_work_hour')}"
              name="normal_work_hour"
              type="number"
              v-model="dailyRecapForm.normal_work_hour"
              placeholder="Normal Worktime">
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">Overtime Workhour (Hours)</label>
            <input
              class="uk-input"
              :class="{'uk-form-danger': errors.has('overtime_work_hour')}"
              name="normal_work_hour"
              type="number"
              v-model="dailyRecapForm.overtime_work_hour"
              placeholder="Normal Worktime">
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">Absent Time (Hours)</label>
            <input
              class="uk-input"
              :class="{'uk-form-danger': errors.has('absent_hour')}"
              name="absent_hour"
              type="number"
              v-model="dailyRecapForm.absent_hour"
              placeholder="Absent Time">
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">Leave Time (Hours)</label>
            <input
              class="uk-input"
              :class="{'uk-form-danger': errors.has('leaves_hour')}"
              name="leaves_hour"
              type="number"
              v-model="dailyRecapForm.leaves_hour"
              placeholder="Leave Time">
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">Daily Total Fee (Rp) <b class="uk-text-danger">*</b></label>
            <input
              class="uk-input"
              :class="{'uk-form-danger': errors.has('fee_total')}"
              name="fee_total"
              type="number"
              v-model="dailyRecapForm.fee_total"
              placeholder="Total Fee" disabled>
              <span class="uk-text-small uk-text-danger" v-show="dailyRecapForm.fee_normal_unit==='Month'">Only overtime fee for monthly payments</span>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">Notes</label>
            <textarea
              class="uk-textarea"
              :class="{'uk-form-danger': errors.has('notes')}"
              name="notes"
              type="text"
              v-model="dailyRecapForm.notes"
              placeholder="Notes"></textarea>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">Updated By <b class="uk-text-danger">*</b></label>
            <input
              class="uk-input"
              :class="{'uk-form-danger': errors.has('updated_by')}"
              name="updated_by"
              type="text"
              v-model="dailyRecapForm.updated_by"
              v-validate="'required'"
              placeholder="Input your name here">
              <span class="uk-text-small uk-text-danger" v-show="errors.has('updated_by')">{{ errors.first('updated_by') }}</span>
          </div>

          <!-- <div class="uk-margin">
            <label class="uk-form-label">
              <input
                class="uk-checkbox uk-margin-small-right"
                name="status"
                type="checkbox"
                v-model="dailyRecapForm.status"
              >
                Reviewed
            </label>
          </div> -->

        </div>
        <div class="uk-modal-footer uk-text-right">
          <button v-if="isLoading" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
          <button v-else class="uk-button uk-button-primary" type="button" @click="saveDailyRecap">Save</button>
        </div>
      </div>
    </div>

    <div id="deleteRecapModal" uk-modal esc-close="false" bg-close="false">
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default" type="button" uk-close @click="hideDeleteModal"></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title">Delete Daily Recap?</h2>
        </div>
        <div class="uk-modal-body">
          <div class="uk-margin-small">
            <div>
              <h5 class="uk-text-bold">Date:</h5>
              <p>{{dailyRecapForm.date ? new Date(dailyRecapForm.date).toLocaleDateString("id-ID", { year: 'numeric', month: 'long', day: 'numeric' }) : null}}</p>
            </div>
            <div>
              <h5 class="uk-text-bold">User Name:</h5>
              <p>{{dailyRecapForm.user_id.name}}</p>
            </div>
            <div>
              <h5 class="uk-text-bold">Company Name:</h5>
              <p>{{dailyRecapForm.company_id.name}}</p>
            </div>
            <div>
              <h5 class="uk-text-bold">Property Name:</h5>
              <p>{{dailyRecapForm.company_office_id.name}}</p>
            </div>
            <div>
              <h5 class="uk-text-bold">Normal Worktime (Hours):</h5>
              <p>{{dailyRecapForm.normal_work_hour}}</p>
            </div>
            <div>
              <h5 class="uk-text-bold">Overtime Worktime (Hours):</h5>
              <p>{{dailyRecapForm.overtime_work_hour}}</p>
            </div>
            <div>
              <h5 class="uk-text-bold">Total Payment (Rp):</h5>
              <p>{{dailyRecapForm.fee_total}}</p>
            </div>

          </div>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button v-if="isLoading" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
          <button v-else class="uk-button uk-button-danger" type="button" @click="MarkRecapDeleted">Delete</button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Pagination from '@/components/globals/Pagination';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import { mapActions, mapGetters } from 'vuex';
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';
import { Datetime } from 'vue-datetime';
import formatter from '@/utils/formatter';

export default {
    data() {
        return {
            meta: {
                limit: 100,
                page: 1,
                user_id: '',
                company_id: '',
                company_office_id: '',
                date: '',
                is_deleted: false,
            },
            dailyRecapForm: {
                date: null,
                user_id: '',
                company_id: '',
                company_office_id: '',
                job_partner_id: '',
                normal_work_hour: null,
                overtime_work_hour: null,
                absent_hour: null,
                leaves_hour: null,
                fee_normal: null,
                fee_normal_unit: '',
                fee_overtime_hourly: null,
                fee_total: 0,
                break_time: null,
                totaltime_includes_break: false,
                notes: '',
                checkin_time: null,
                checkout_time: null,
                updated_by: '',
                // status: '',
            },
            reportForm: {
                company_id: '',
                company_office_id: '',
                start_date: null,
                end_date: null
            },
            report_fields: {
                'WH ID': {
                    callback: (value) => {
                        return `${value.company_office.type.code} - ${value.company_office.code}`;
                    }
                },
                'WH Name': 'company_office.name',
                'Employee Name': 'user.fullname',
                'Over time (hours)': 'overtime_work_hour',
                'Total Time spent (hours)': {
                    callback: (value) => {
                        return `${value.normal_work_hour + value.overtime_work_hour}`;
                    }
                },

                'Total Payment (Rp)': {
                    callback: (value) => {
                        if (value.fee_normal_unit === 'Month') {
                            return `${value.fee_total + (value.fee_normal / 2)}`;
                        }
                        return `${value.fee_total}`;
                    }
                }
            },
            attendance_date: null,
            unit_options: ['Hour', 'Day', 'Month'],
            filterType: null,
            formIsEdit: false,
            isLoading: false,
            isFetching: false,
            feeFilled: false,
            detailIsActive: false,
            company_options: [],
            office_options: [],
            job_partner_options: [],
            user_options: [],
            notes: '',
        };
    },
    components: {
        Pagination,
        EmptyTable,
        Datetime
    },
    computed: {
        ...mapGetters({
            property_role_settings: 'property_role_setting/property_role_settings',
            job_partners: 'job_partner/job_partners',
            job_partner: 'job_partner/job_partner',
            activity_logs: 'daily_recap/activity_logs',
            daily_recaps: 'daily_recap/daily_recaps',
            grouped_recap: 'daily_recap/grouped_recap',
            companies: 'company/companies',
            properties: 'property/properties',
            users: 'user/users',
        }),
        feeModifier() {
            const {
                normal_work_hour, overtime_work_hour,
                fee_normal, fee_overtime_hourly,
                fee_normal_unit,
            } = this.dailyRecapForm;

            return {
                normal_work_hour, overtime_work_hour,
                fee_normal, fee_overtime_hourly,
                fee_normal_unit,
            };
        },
        metaModifier() {return this.meta;}
    },
    watch: {
        metaModifier: {
            handler: function() {
                this.setDailyRecapMeta();
            },
            deep:true
        },
        feeModifier: {
            handler: function() {
                if (!this.detailIsActive) {
                    this.dailyRecapForm.fee_total = this.calculateTotalFee();
                }
            },
            deep:true
        },
        filterType: {
            handler: function() {
                this.meta = {
                    ... this.meta,
                    user_id: '',
                    company_id: '',
                    company_office_id: '',
                };
            }
        }
    },
    mounted() {
        this.setDailyRecapMeta();
    },
    methods: {
        ...mapActions({
            getPropertyRoleSetting: 'property_role_setting/getPropertyRoleSetting',
            updateEmployerEditFlags: 'user_attendance/updateEmployerEditFlags',
            autoCreateDailyRecap: 'daily_recap/autoCreateDailyRecap',
            getDailyRecaps: 'daily_recap/getDailyRecaps',
            getGroupedRecap: 'daily_recap/getGroupedRecap',
            createDailyRecap: 'daily_recap/createDailyRecap',
            updateDailyRecap: 'daily_recap/updateDailyRecap',
            deleteDailyRecap: 'daily_recap/deleteDailyRecap',
            getJobPartners: 'job_partner/getJobPartners',
            getActivityLog: 'daily_recap/getActivityLog',
            getCompanies: 'company/getCompanies',
            getProperties: 'property/getProperties',
            getUser: 'user/getUser',
        }),
        async setCompanyValues(query) {
            this.isFetching = true;

            await this.getCompanies({name: query});
            this.company_options = this.companies.docs.map(
                obj => ({name: obj.name, value: obj._id})
            );

            this.isFetching = false;
        },
        async setPropertyValues(query) {
            this.isFetching = true;

            await this.getProperties({name: query});
            this.office_options = this.properties.docs.map(
                obj => ({name: obj.name, value: obj._id})
            );

            this.isFetching = false;
        },
        async setJobPartnerValues(query) {
            this.isFetching = true;

            await this.getJobPartners({user_id: query});
            this.job_partner_options = this.job_partners.docs.map(
                obj => ({
                    name: `${obj.user.fullname} (${obj.company.name}, ${obj.job.title})`,
                    value: obj._id,
                    role_property: {
                        role_id: obj.role._id,
                        company_office_id: obj.company_office._id,
                    }
                })
            );

            this.isFetching = false;
        },
        async setUserValues(query, onlyPartner = false) {
            this.isFetching = true;

            await this.getUser({fullname: query, only_partner: onlyPartner});
            this.user_options = this.users.docs.map(
                obj => ({name: `${obj.fullname} (${obj.email})`, value: obj._id})
            );

            this.isFetching = false;
        },
        async fillFees(role_property) {
            await this.getPropertyRoleSetting({role_id: role_property.role_id, company_office_id: role_property.company_office_id});

            const role_property_setting = this.property_role_settings.docs[0];
            if (role_property_setting) {
                [
                    'break_time', 'totaltime_includes_break',
                    'fee_normal', 'fee_overtime_hourly', 'fee_normal_unit'
                ].forEach(prop => {
                    this.dailyRecapForm[prop] = role_property_setting[prop];
                });

                this.feeFilled = true;
                setTimeout(() => this.feeFilled = false, 2500);
            }
        },
        calculateTotalFee() {
            if (!this.dailyRecapForm.normal_work_hour && !this.dailyRecapForm.overtime_work_hour) {
                return 0;
            }
            switch (this.dailyRecapForm.fee_normal_unit) {
            case 'Hour':
                return (
                    (this.dailyRecapForm.normal_work_hour * this.dailyRecapForm.fee_normal)
                      +
                    (this.dailyRecapForm.overtime_work_hour * this.dailyRecapForm.fee_overtime_hourly)
                );
            case 'Day':
                return (
                    this.dailyRecapForm.fee_normal * 1
                      +
                    (this.dailyRecapForm.overtime_work_hour * this.dailyRecapForm.fee_overtime_hourly)
                );
            case 'Month':
                return (this.dailyRecapForm.overtime_work_hour * this.dailyRecapForm.fee_overtime_hourly);
            }
        },
        onQueryChange(query) {
            if (this.filterType === 'user_id') this.setUserValues(query, true);
            else if (this.filterType === 'company_id') this.setCompanyValues(query);
            else this.setPropertyValues(query);
        },
        setDailyRecapMeta() {
            this.getDailyRecaps({
                ...this.meta,
                user_id: this.meta.user_id.value,
                company_id: this.meta.company_id.value,
                company_office_id: this.meta.company_office_id.value
            });
        },
        changeMeta(e, prop) {
            this.meta = {
                ...this.meta,
                [prop]: e.target.value
            };
            this.getDailyRecaps({...this.meta, user_id: this.meta.user_id.value});
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        async saveDailyRecap() {
            try {
                const validate = await this.$validator.validateAll();
                if (!validate || this.$validator.errors.any()) return;

                this.isLoading = true;

                [
                    'company_id', 'company_office_id', 'user_id', 'job_partner_id'
                ].forEach(prop => {
                    this.dailyRecapForm[prop] = this.dailyRecapForm[prop].value;
                });

                [
                    'break_time', 'absent_hour', 'leaves_hour',
                    'normal_work_hour', 'overtime_work_hour'
                ].forEach(prop => {
                    if (!this.dailyRecapForm[prop]) this.dailyRecapForm[prop] = 0;
                });
                ['checkin_time', 'checkout_time'].forEach(prop => {
                    if (!this.dailyRecapForm[prop])  this.dailyRecapForm[prop] = null;
                });
                this.dailyRecapForm.overtime_work_hour_spv = this.dailyRecapForm.overtime_work_hour;

                // if (this.dailyRecapForm.status) this.dailyRecapForm.status = 1;
                // else this.dailyRecapForm.status = 0;

                const response = this.formIsEdit
                    ? await this.updateDailyRecap(this.dailyRecapForm)
                    : await this.createDailyRecap(this.dailyRecapForm);

                this.isLoading = false;
                if (response && response.status === 'OK') {
                    notificationSuccess('Daily recap saved!');
                    this.hideModal();
                    this.setDailyRecapMeta();
                } else {
                    notificationDanger('Failed to save daily recap.');
                }
            } catch (err) {
                notificationDanger(err);
            }
        },
        async createFromAttd() {
            try {
                const validate = await this.$validator.validateAll('attd-form');
                if (!validate || this.$validator.errors.any()) return;

                const response = await this.autoCreateDailyRecap({date: this.attendance_date});

                if (response && response.status === 'OK') {
                    notificationSuccess('Daily recap created!');
                    this.hideModalAttd();
                    this.setDailyRecapMeta();
                } else {
                    notificationDanger('Failed to create daily recap.');
                }
            } catch (err) {
                notificationDanger(err);
            }
        },
        async fillForm(daily_recap, isDetail = false) {
            try {
                if (isDetail) {
                    this.dailyRecapForm.updated_by = daily_recap.updated_by;
                    this.dailyRecapForm.updated_at = daily_recap.updated_at;
                }
                this.dailyRecapForm.id = daily_recap._id;
                [
                    'date', 'normal_work_hour', 'overtime_work_hour',
                    'absent_hour', 'leaves_hour', 'fee_total', 'break_time',
                    'fee_normal', 'fee_normal_unit', 'fee_overtime_hourly', 'notes',
                    'checkin_time', 'checkout_time', 'totaltime_includes_break'
                ].forEach(prop => {
                    this.dailyRecapForm[prop] = daily_recap[prop];
                });
                // this.dailyRecapForm.status = daily_recap.status === 1;
                this.dailyRecapForm.user_id = {
                    name: `${daily_recap.user.fullname} (${daily_recap.user.email})`,
                    value: daily_recap.user._id
                };
                this.dailyRecapForm.company_id = {
                    name: daily_recap.company.name,
                    value: daily_recap.company._id
                };
                this.dailyRecapForm.company_office_id = {
                    name: daily_recap.company_office.name,
                    value: daily_recap.company_office._id
                };
                await this.setJobPartnerValues(daily_recap.user._id);
                this.dailyRecapForm.job_partner_id = this.job_partner_options.find(
                    item => item.value === daily_recap.job_partner._id
                );

            } catch (err) {
                notificationDanger(err);
            }
        },
        editDailyRecap(daily_recap) {
            this.formIsEdit = true;
            this.fillForm(daily_recap);
            this.showModal();
        },
        async MarkRecapDeleted() {
            try {
                this.isLoading = true;

                const response = await this.deleteDailyRecap(this.dailyRecapForm.id);

                this.isLoading = false;

                if (response && response.status === 'OK') {
                    notificationSuccess('Daily recap deleted!');
                    this.hideDeleteModal();
                    this.setDailyRecapMeta();
                } else {
                    notificationDanger('Failed to delete daily recap.');
                }
            } catch (err) {
                notificationDanger(err);
            }
        },
        formatDatetime(date, shortDate) {
            if (!date) return null;
            return shortDate ? formatter.datetimeShort(date) : formatter.datetimeComplete(date);
        },
        formatDate(date) {
            if (!date) return null;
            return formatter.dateComplete(date);
        },
        resetForm() {
            this.formIsEdit = false;
            this.dailyRecapForm = {
                date: null,
                user_id: '',
                company_id: '',
                company_office_id: '',
                job_partner_id: '',
                normal_work_hour: null,
                overtime_work_hour: null,
                absent_hour: null,
                leaves_hour: null,
                fee_normal: null,
                fee_normal_unit: '',
                fee_overtime_hourly: null,
                fee_total: 0,
                break_time: null,
                totaltime_includes_break: false,
                notes: '',
                checkin_time: null,
                checkout_time: null,
                updated_by: '',
                // status: '',
            };
            this.reportForm = {
                company_id: '',
                company_office_id: '',
                start_date: null,
                end_date: null
            };
            this.$validator.reset();
        },
        async downloadReport() {
            try {
                const validate = await this.$validator.validateAll('report-form');
                if (!validate || this.$validator.errors.any()) return;

                this.reportForm.company_id = this.reportForm.company_id.value;
                this.reportForm.company_office_id = this.reportForm.company_office_id.value;

                notificationSuccess('Downloading data.....');
                const response = await this.getGroupedRecap(this.reportForm);

                if (response && response.status === 'OK') {
                    this.$refs.report_download.$el.click();
                    notificationSuccess('Payment report downloaded');
                } else {
                    notificationDanger('Failed to download payment report!');
                }
                this.hideModalReport();
            } catch (err) {
                notificationDanger(err);
            }
        },
        async updateEmployerFlags(attendance_id, isSPV) {
            try {
                const flags = isSPV ? {employer_spv_update: null} : {employer_hr_update: null};
                const response = await this.updateEmployerEditFlags({...flags, id: attendance_id});

                if (response && response.status === 'OK') {
                    notificationSuccess('Success updated employer edit flags');
                } else {
                    notificationDanger('Failed to update employer edit flags');
                }
            } catch (err) {
                notificationDanger(err);
            }
        },
        async showDetail(daily_recap) {
            this.detailIsActive = true;
            this.fillForm(daily_recap, true);
            await this.getActivityLog({daily_recap_id: daily_recap._id});
            await window.UIkit.modal('#recapDetailModal').show();
        },
        async hideDetail() {
            await window.UIkit.modal('#recapDetailModal').hide();
            this.detailIsActive = false;
            this.resetForm();
        },
        async showNotes(selected_notes) {
            this.notes = selected_notes;
            await window.UIkit.modal('#recapNotesModal').show();
        },
        async hideNotes() {
            this.notes = '';
            await window.UIkit.modal('#recapNotesModal').hide();
        },
        async showDeleteModal(daily_recap) {
            this.fillForm(daily_recap);
            await window.UIkit.modal('#deleteRecapModal').show();
        },
        async hideDeleteModal() {
            await window.UIkit.modal('#deleteRecapModal').hide();
            this.resetForm();
        },
        async showModalReport() {
            await window.UIkit.modal('#formReportModal').show();
        },
        async hideModalReport() {
            await window.UIkit.modal('#formReportModal').hide();
            this.resetForm();
        },
        async showModal() {
            await window.UIkit.modal('#formRecapModal').show();
        },
        async showModalAttd() {
            await window.UIkit.modal('#formRecapAttdModal').show();
        },
        async hideModalAttd() {
            await window.UIkit.modal('#formRecapAttdModal').hide();
            this.attendance_date = null;
        },
        async hideModal() {
            await window.UIkit.modal('#formRecapModal').hide();
            this.resetForm();
        },
        onCopy(e) {
            notificationSuccess('Copied to clipboard: ' + e.text);
        },
    }
};
</script>
